import { Inject, Injectable } from '@angular/core';
import { IToastShowOptions } from './toastShowOptions';
import { IndividualConfigExtended } from './individualConfigExtended.interface';
import { SvgToastComponent } from './svgToastComponent';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { TOAST_CONFIGURATION } from './toastModuleTokens';
import { IToastOptions } from './toastOptions';

const displayedToasts = {};
const defaultTimeout = 5000;

@Injectable({providedIn: 'root'})
export class ToastManager {
  private _config: IToastOptions;

  constructor(@Inject(TOAST_CONFIGURATION) configuration: IToastOptions, private _toastr: ToastrService) {
    this._config = configuration;
  }

  success(message: string, options?: IToastShowOptions): any {
    options = {
      icon: 'success',
      iconPrefix: 'toastIcon',
      ...options,
    };
    return this.showToast('success', message, options);
  }

  warning(message: string, options?: IToastShowOptions): any {
    options = {
      icon: 'warning',
      iconPrefix: 'toastIcon',
      ...options,
    };
    return this.showToast('warning', message, options);
  }

  error(message: string, options?: IToastShowOptions): any {
    options = {
      icon: 'error',
      iconPrefix: 'toastIcon',
      ...options,
    };
    return this.showToast('error', message, options);
  }

  info(message: string, options?: IToastShowOptions): any {
    options = {
      icon: 'info',
      iconPrefix: 'toastIcon',
      ...options,
    };
    return this.showToast('info', message, options);
  }

  notify(message: string, options?: IToastShowOptions): any {
    options = {
      icon: 'info',
      iconPrefix: 'toastIcon',
      showClose: true,
      cssClass: 'notificationToast',
      targetSelector: '.notificationsContainer',
      ...options,
    };
    return this.showToast('show', message, options);
  }

  clearToast(toast: any, withoutAnimation?: boolean) {
    if (!toast) {
      return;
    }
    if (withoutAnimation) {
      this._toastr.remove(toast.toastId);
    } else {
      this._toastr.clear(toast.toastId);
    }
    const toastEntry = Object.entries(displayedToasts).find(e => e[1] === toast);
    if (toastEntry) {
      delete displayedToasts[toastEntry[0]];
    }
  }

  clearToastByKey(toastKey: string, withoutAnimation?: boolean) {
    const toast = displayedToasts[toastKey] as ActiveToast<any>;
    this.clearToast(toast, withoutAnimation);
  }

  private showToast(toastType: string, message: string, options: IToastShowOptions) {
    options = {
      title: '',
      position: 'toast-bottom-right',
      targetSelector: '',
      buttons: [],
      showClose: false,
      targetFirst: true,
      ...options,
    };
    const newToast = this._toastr[toastType](message, options.title, this.getToastrConfig(options)) as ActiveToast<any>;
    if (options.toastKey) {
      if (displayedToasts[options.toastKey]) {
        const existingToast = displayedToasts[options.toastKey] as ActiveToast<any>;
        this._toastr.remove(existingToast.toastId);
      }
      // set flag to be able to check if toast should be opened or closed
      displayedToasts[options.toastKey] = newToast;
    }
    newToast.onHidden.subscribe(() => {
      if (options.toastKey && displayedToasts[options.toastKey] && displayedToasts[options.toastKey] === newToast) {
        delete displayedToasts[options.toastKey];
      }
    });
    return newToast;
  }

  private getToastClass(cssClass?: string) {
    return 'toastModule toast ' + (cssClass || this._config.cssClass || '');
  }

  private getToastrConfig(options: IToastShowOptions): Partial<IndividualConfigExtended> {
    options = {
      timeOut: this._config.timeOut,
      cssClass: this._config.cssClass,
      toastComponent: SvgToastComponent, // default toast component
      ...options
    };
    return {
      toastClass: this.getToastClass(options.cssClass),
      disableTimeOut: !options.timeOut,
      timeOut: (options.timeOut === undefined || options.timeOut === null ? this._config.timeOut || defaultTimeout : options.timeOut) as number,
      extendedTimeOut: 0,
      closeButton: options.showClose,
      positionClass: options.position,
      toastComponent: options.toastComponent,
      svgIcon: options.icon,
      svgIconPrefix: options.iconPrefix,
      buttons: options.buttons,
      tapToDismiss: !!options.timeOut
    };
  }
}
