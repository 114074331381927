import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CoreUIModule } from 'ngx-myia-core-ui';
import { ToastrModule } from 'ngx-toastr';
import { SvgToastComponent } from '../svgToastComponent';
import { TOAST_CONFIGURATION } from '../toastModuleTokens';
import { IToastOptions } from '../toastOptions';

require('../icons/symbols/toast.symbol.svg'); // load all svg icons symbols

@NgModule({
    imports: [
        CommonModule,
        CoreUIModule,
        ToastrModule.forRoot()
    ],
    declarations: [
        SvgToastComponent
    ],
    entryComponents: [
        SvgToastComponent
    ],
    exports: [
        SvgToastComponent
    ]
})
export class ToastModule {
    static forRoot(config?: IToastOptions): ModuleWithProviders {
        return {
            ngModule: ToastModule,
            providers: [
                {
                    provide: TOAST_CONFIGURATION, useValue: config ? config : {}
                }
            ]
        };
    }
}
